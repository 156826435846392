<template>
    <div id="" class="container max-w-4xl mx-auto pt-5 pb-20 px-6">
        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl">
                Classifiche
            </div>

        </div>

        <div class="bg-green-500 w-full h-1 my-4"></div>
        <div v-if="concorsi">
            <div class="flex py-2 font-bold items-center">

                <div class="w-2/12">
                    Dettaglio
                </div>
                <div class="w-3/12">
                    Concorso
                </div>

                <div class="w-3/12">
                    Cognome e Nome
                </div>

                <div class="w-2/12">
                    Nazionalità
                </div>
                <div class="w-2/12 text-right">
                    Punteggio
                </div>
            </div>


            <div v-if="teams" class="flex py-2 items-center">

                <div class="w-2/12 ml-4">
                    <router-link :to="{ name: 'DettaglioClassificaTeams' }">
                        <i class="fas fa-search fa-1x mr-4"></i>
                    </router-link>

                </div>

                <div class="w-3/12">
                    Teams
                </div>

                <div class="w-3/12">
                    {{ ( teams.length ? teams[0].nome_concorso : '' ) }}
                </div>

                <div class="w-2/12">
                    
                </div>

                <div class="w-2/12 text-right mr-2">
                    {{ ( teams.length ? teams[0].voto_complessivo : '' ) }}
                </div>
            </div>

            <div v-for="concorso in concorsi" :key="concorso.id" id="classifica-concorsi" class="flex py-2 items-center">
                <div class="w-2/12 ml-4">
                    <router-link :to="{ name: 'DettaglioClassifica', params: { id: concorso.concorso_id } }">
                        <i class="fas fa-search fa-1x mr-4"></i>
                    </router-link>
                </div>

                <div class="w-3/12">
                    {{ concorso.nome_concorso }}
                </div>

                <div class="w-3/12">
                    {{ concorso.cognome }} {{ concorso.nome }}
                </div>

                <div class="w-2/12">
                    {{ concorso.nazionalita }}
                </div>

                <div class="w-2/12 text-right mr-2">
                    {{ concorso.voto }}
                </div>
            </div>
        </div>
        <div class="w-full pl-4" v-else>
            <div class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                <span class="font-medium mr-3"><i class="fa-solid fa-spinner fa-spin"></i></span> Caricamento in corso
              </div>
        </div>

    </div>

    



</template>

<style>
#classifica-concorsi {
    background-color: #fff;
}

#classifica-concorsi:nth-child(even) {
    background-color: #f2f2f2;
}
</style>

<script setup>
import axios from 'axios'
import { onMounted, ref } from 'vue'

const concorsi = ref(null)
const teams = ref(null)

async function fetchConcorsi() {
    const response = await axios.get('/api/classifiche-dashboard')
    concorsi.value = response.data
}


async function fetchTeams() {
    const response = await axios.get('/api/teams/classifica')
    
    teams.value = response.data
}

onMounted(() => {
    fetchConcorsi()
    fetchTeams()

    // Richiama fetchCounters ogni 5 minuti
    setInterval(() => {
        fetchConcorsi();
        fetchTeams();
    }, 300000); // 300000 millisecondi = 5 minuti
})
</script>