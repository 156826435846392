import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegisterView from '../views/RegisterView.vue'
import LoginView from '../views/LoginView.vue'
import AccountView from '../views/AccountView.vue'
import DashboardView from '../views/DashboardView.vue'
import CreateCliente from '../views/cliente/CreateCliente.vue'
import ShowClienti from '../views/cliente/ShowClienti.vue'
import DettaglioCliente from '../views/cliente/DettaglioCliente.vue'
import VotaCliente from '../views/giudice/VotaCliente.vue'
import TestQrCode from '../views/account/TestQrCode.vue'
import ShowGiudici from '../views/giudice/ShowGiudici.vue'
import ShowVoti from '../views/admin/ShowVoti.vue'
import DettaglioClassifica from '../views/cliente/DettaglioClassifica.vue'
import DashboardGiudice from '../views/giudice/DashboardGiudice.vue'
import VotaManuale from '../views/giudice/VotaManuale.vue'
import ManualQrCode from '../views/assistenza/ManualQrCode.vue'
import OttieniShortCode from '../views/assistenza/OttieniShortCode.vue'
import DashboardAssistenza from '../views/assistenza/DashboardAssistenza.vue'
import ShowTeams from '../views/team/ShowTeams.vue'
import DettaglioTeam from '../views/team/DettaglioTeam.vue'
import CreateTeam from '../views/team/CreateTeam.vue'
import DettaglioClassificaTeams from '../views/team/DettaglioClassificaTeams.vue'
import EditOrder from '../views/cliente/EditOrder.vue'
import ShowVotiGiudice from '../views/admin/ShowVotiGiudice.vue'
import DownloadCSV from '../views/admin/DownloadCSV.vue'
import EtichetteCliente from '../views/cliente/EtichetteCliente.vue'
import DashboardForno from '../views/forno/DashboardForno.vue'
import EditForno from '../views/forno/EditForno.vue'
import DateConcorsi from '../views/date/ShowDate.vue'
import CreateDate from '../views/date/CreateDate.vue'
import ModificaDate from '../views/date/ModificaDate.vue'

import MapsClassifiche from '../views/cliente/MapsClassifiche.vue'

import DettaglioClassificaEta from '../views/cliente/DettaglioClassificaEta.vue'

import DettaglioClassificaCategoria from '../views/cliente/DettaglioClassificaCategoria.vue'

import DettaglioClassificaStraniero from '../views/cliente/DettaglioClassificaStraniero.vue'

import DettaglioClassificaItaliano from '../views/cliente/DettaglioClassificaItaliano.vue'

import DettaglioClassificaDonna from '../views/cliente/DettaglioClassificaDonna.vue'

import DettaglioClassificaSpecial from '../views/cliente/DettaglioClassificaSpecial.vue'

import DettaglioClassificaTop from '../views/cliente/DettaglioClassificaTop.vue'

import DettaglioClassificaBrand from '../views/cliente/DettaglioClassificaBrand.vue'

import DettaglioClassificaGiudiciForno from '../views/cliente/DettaglioClassificaGiudiciForno.vue'

import DettaglioClassificaPanuozzo from '../views/cliente/DettaglioClassificaPanuozzo.vue'



import ShowConcorsi from '../views/admin/ShowConcorsi.vue'

import { useUserStore } from '@/store/user-store';




const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {
      requiresAuth: true,
      roles: ['A'] 
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true,
      roles: ['A', 'U'] 
    }
  },
  {
    path: '/download-csv',
    name: 'DownloadCSV',
    component: DownloadCSV,
    meta: {
      requiresAuth: true,
      roles: ['A'] 
    }
  },
  {
    path: '/mapclassifiche',
    name: 'MapsClassifiche',
    component: MapsClassifiche,
    meta: {
      requiresAuth: true,
      roles: ['A'] 
    }
  },
  {
    path: '/dashgiudice',
    name: 'DashboardGiudice',
    component: DashboardGiudice,
    meta: {
      requiresAuth: true,
      roles: ['G'] 
    }
  },
  {
    path: '/dashassistenza',
    name: 'DashboardAssistenza',
    component: DashboardAssistenza,
    meta: {
      requiresAuth: true,
      roles: ['H'] 
    }
  },
  {
    path: '/dashforno',
    name: 'DashboardForno',
    component: DashboardForno,
    meta: {
      requiresAuth: true,
      roles: ['F']
    }
  },
  {
    path: '/testQR',
    name: 'TestQrCode',
    component: TestQrCode,
    meta: {
      requiresAuth: true,
      roles: ['G'] 
    }
  },
  {
    path: '/manualQR',
    name: 'ManuaQrCode',
    component: ManualQrCode,
    meta: {
      requiresAuth: true,
      roles: ['H'] 
    }
  },
  {
    path: '/ottieniShortCode/:id',
    name: 'OttieniShortCode',
    component: OttieniShortCode,
     meta: {
      requiresAuth: true,
      roles: ['H'] 
    } 
  },
  {
    path: '/clienti',
    component: AccountView,
    meta: {
      requiresAuth: true,
      roles: ['A', 'U'] 
    },
    children: [
      {
        path: 'show',
        name: 'ShowClienti',
        component: ShowClienti
      },
      {
        path: 'create-cliente',
        name: 'CreateCliente',
        component: CreateCliente
      },
      {
        path: 'dettaglio-cliente/:id',
        name: 'DettaglioCliente',
        component: DettaglioCliente
      },
      {
        path: 'add-dettaglio-ordine/:id/:cliente_id',
        name: 'EditOrder',
        component: EditOrder
      },
      {
        path: 'classifica-eta',
        name: 'DettaglioClassificaEta',
        component: DettaglioClassificaEta,
      },
      {
        path: 'classifica-giudici-forno',
        name: 'DettaglioClassificaGiudiciForno',
        component: DettaglioClassificaGiudiciForno
      },
      {
        path: 'classifica-panuozzo',
        name: 'DettaglioClassificaPanuozzo',
        component: DettaglioClassificaPanuozzo
        
      },
      {
        path: 'classifica-categoria',
        name: 'DettaglioClassificaCategoria',
        component: DettaglioClassificaCategoria,
      },
      {
        path: 'classifica-miglior-straniero',
        name: 'DettaglioClassificaStraniero',
        component: DettaglioClassificaStraniero,
      },
      {
        path: 'classifica-miglior-italiano',
        name: 'DettaglioClassificaItaliano',
        component: DettaglioClassificaItaliano,
      },
      {
        path: 'classifica/:id',
        name: 'DettaglioClassifica',
        component: DettaglioClassifica,
      },
      {
        path: 'classifica-miglior-donna',
        name: 'DettaglioClassificaDonna',
        component: DettaglioClassificaDonna,
      },
      {
        path: 'classifica-miglior-cat-special',
        name: 'DettaglioClassificaSpecial',
        component: DettaglioClassificaSpecial,
      },
      {
        path: 'classifica-miglior-cat-top',
        name: 'DettaglioClassificaTop',
        component: DettaglioClassificaTop,
      },
      {
        path: 'classifica-miglior-brand',
        name: 'DettaglioClassificaBrand',
        component: DettaglioClassificaBrand,
      },
      
    ]
  },
  {
    path: '/edit-forno/:id',
    name: 'EditForno',
    component: EditForno,
    meta: {
      requiresAuth: true,
      roles: ['F'] 
    }
  },
  {
    path: '/admin-concorsi',
    name: 'ShowConcorsi',
    component: ShowConcorsi,
    meta: {
      requiresAuth: true,
      roles: ['A']
    }
  },
  {
    path: '/giudici',
    component: AccountView,
    meta: {
      requiresAuth: true,
      roles: ['A'] 
    },
    children: [
      {
        path: 'show',
        name: 'ShowGiudici',
        component: ShowGiudici,
        meta: {
          requiresAuth: true,
          roles: ['A']
        }
      },
      {
        path: 'admin-voti',
        name: 'ShowVoti',
        component: ShowVoti,
        meta: {
          requiresAuth: true,
          roles: ['A']
        }
      },     
      
      {
        path: 'voti-giudice/:id',
        name: 'ShowVotiGiudice',
        component: ShowVotiGiudice,
        meta: {
          requiresAuth: true,
          roles: ['A']
        }
      },
    ]
  },
  {
    path: '/vota-pizza/:id',
    name: 'VotaCliente',
    component: VotaCliente,
    meta: {
      requiresAuth: true,
      roles: ['G'] 
    }
  },
  {
    path: '/vota-manuale/',
    name: 'VotaManuale',
    component: VotaManuale,
    meta: {
      requiresAuth: true,
      roles: ['G'] 
    }
  },
  {
    path: '/printEtichette/',
    name: 'EtichetteCliente',
    component: EtichetteCliente,
    meta: {
      requiresAuth: true,
      roles: ['A']
    }
  },
  {
    path: '/date-concorsi/',
    
    meta: {
      requiresAuth: true,
      roles: ['A','U']  
    },
    children: [
      {
        path: 'show',
        name: 'ShowDate',
        component: DateConcorsi,
        meta: {
          requiresAuth: true,
          roles: ['A','U'] 
        },
      },  
      {
        path: 'aggiungi-data',
        name: 'CreateDate',
        component: CreateDate,
        meta: {
          requiresAuth: true,
          roles: ['A','U'] 
        },
      },
      {
        path: 'modifica-data/:id',
        name: 'ModificaDate',
        component: ModificaDate,
        meta: {
          requiresAuth: true,
          roles: ['A','U'] 
        },
      },
      
    ]
  },
  {
    path: '/teams',
    component: AccountView,
    meta: {
      requiresAuth: true,
      roles: ['A', 'U'] 
    },
    children: [
      {
        path: 'show',
        name: 'ShowTeams',
        component: ShowTeams
      },
      {
        path: 'classifica',
        name: 'DettaglioClassificaTeams',
        component: DettaglioClassificaTeams
      },
      {
        path: 'dettaglio-team/:id',
        name: 'DettaglioTeam',
        component: DettaglioTeam
      },
      {
        path: 'create-team',
        name: 'CreateTeam',
        component: CreateTeam
      },      
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {

  const userStore = useUserStore();

 /*  console.log("userStore: ", userStore);
  console.log("test userStore: ", !!userStore)
  console.log("userStore.id: ", userStore.id)
  console.log("test userStore.id: ", userStore.id === 'number')
  console.log("userStore.id: ", userStore.id)
  console.log("test userStore.id isNaN: ", !isNaN(userStore.id)) */

  const isAuthenticated = !!userStore && typeof userStore.id === 'number' && !isNaN(userStore.id); 

  console.log("Auth", isAuthenticated);

  const userRole = userStore ? userStore.role : ""; 

  if (!isNaN(userStore.id)) { console.log('ID: ', userStore.id); }

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'login' });
  } else if (to.meta.roles && !to.meta.roles.includes(userRole)) {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router;