<template>
    <TopNavigation />
    <div id="ShowDate" class="container max-w-4xl mx-auto pt-5 pb-20 px-6">
        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl">
                Date
            </div>
            <RouterLinkButton btnText="Nuova Data" color="green" url="/date-concorsi/aggiungi-data" />
        </div>

        <div class="bg-primary w-full h-1 my-4"></div>
        <div v-if="ConcorsiDate">

                <div class="relative overflow-x-auto">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Data evento
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Azioni
                                </th>                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="ConcorsiData in ConcorsiDate" :key="ConcorsiData.id" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ ConcorsiData.id }}
                                </th>
                                <td class="px-6 py-4">
                                    {{ formatDate(ConcorsiData.data_concorso) }}
                                </td>
                                <td class="px-6 py-4">
                                    <router-link class="bg-trasparent mr-4 text-green-500 font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded hover:bg-green-500 border-green-500" :to="{ name: 'ModificaDate', params: { id: ConcorsiData.id } }">
                                        Modifica data
                                    </router-link>
<!--                                     <RouterLinkButton btnText="Modifica data" color="green" :to="{ name: 'ModificaDate', params: { id: ConcorsiData.id } }" />
-->                                 <button class=" bg-trasparent text-red-500 font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded hover:bg-red-500 border-red-500" @click="DeleteDate(ConcorsiData.id)">Elmina</button>
                                </td>                               
                            </tr>
                        </tbody>
                    </table>
                </div>


            <div class="bg-primary w-full h-1 my-4"></div>
            
        </div>
        <div class="w-full pl-4" v-else>
            Caricamento in corso...
        </div>
    </div>
    <FooterSection />
</template>


<script setup>
import TopNavigation from "../../components/structure/TopNavigation.vue";
import FooterSection from '../../components/structure/FooterSection.vue';
import RouterLinkButton from '../../components/global/RouterLinkButton.vue'
import axios from 'axios'
import { onMounted, ref } from 'vue'
import $wal from '../../sweetalert2';
import { useRouter } from 'vue-router';

const ConcorsiDate = ref([])
const router = useRouter()

/* const DeleteDate = async (id) => {
    errors.value = []


    console.log(id);
} */

/* function DeleteDate(id){ */

const DeleteDate = async (id) => {

    try {
        await axios.delete(`api/date-concorsi/${id}`)
        $wal.fire(
            'Data eliminata!',
            '',
            'success'
        )

        router.push('/date-concorsi/show')

    } catch (err) {
        console.log(err);
        /* errors.value = err.response.data.errors; */
    }
}

function getDates() {
    axios.get('/api/date-concorsi')
        .then(res => {
            ConcorsiDate.value = res.data          
        })
}

function formatDate(value) {
    return new Date(value).toLocaleDateString();
}

/*var todaysDate = new Date();
 function eOggi( $data ){
    const dateString = $data;
    const changedDate = dateString.replace(/(..)\/(..)\/(....)/, '$3-$2-$1');
    var date = new Date(changedDate);
    if(date.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
        return 'è oggi';
    }else{
        return 'non è oggi';
    }
} */

/* function correggi_data($data){
    const dateString = $data;
    const changedDate = dateString.replace(/(..)\/(..)\/(....)/, '$3-$2-$1');
    var date = new Date(changedDate);
    return date;
} */

onMounted(() => {
    getDates()
})
</script>