<template>
    <div id="" class="container mx-auto pt-5 pb-20 px-6">

        <RouterLinkButton class="text-center" btnText="Torna alle classifiche" color="green" url="/mapclassifiche" />

        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl">
                Classifica Giudici di forno
            </div>
        </div>


        <div class="flex gap-6 items-center mt-10">
            <div class="search">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 dark:text-white">Cerca per nazionalità</label>

                <select v-model="searchQueryNazionalita"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 ps-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="" selected>Tutte</option>
                    <option v-for="nazione in nazioni_list" :value="nazione" :key="nazione">{{nazione}}</option>
                </select>
            </div>
            <div class="search">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 dark:text-white">Cerca per concorso</label>

                <select v-model="searchQueryConcorso"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 ps-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="" selected>Tutte</option>
                    <option v-for="concorsi_list in lista_concorsi" :value="concorsi_list" :key="concorsi_list">{{concorsi_list}}</option>
                </select>
            </div>
        </div>
        <div class="bg-green-500 w-full h-1 my-4"></div>
        <div v-if="classifica">
            <div class="scroll-container">
                <div class="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <!-- <th style="width: 50px;" class="text-center"></th> -->
                                <th style="width: 30px;" class="text-right">Pos.</th>
                                <th style="width: 60px;" class="text-left">Categoria</th>
                                <th style="width: 60px;" class="text-left">QrCode</th>
                                <th style="width: 280px;" class="text-left">Nome e Cognome</th>
                                <th style="width: 60px;" class="text-left">Età</th>
                                <th style="width: 150px;" class="text-left">Nazionalità</th>
                                <th style="width: 150px;" class="text-right">Stato</th>
                                <th style="width: 150px;" class="text-right">Stato forno</th>
                                <th style="width: 150px;" class="text-right">Voto Totale</th>
                                <!-- <th style="width: 150px;" class="text-right">Voto Aspetto</th>
                                <th style="width: 150px;" class="text-right">Voto Cottura</th>
                                <th style="width: 150px;" class="text-right">Voto Gusto</th> -->
                                <th style="width: 150px;" class="text-right">Voto forno</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(client, index) in filteredConcorsi" :key="client.cliente_shortCode" :data-conc-cliente-short="client.concorso_cliente_shortcode"
                            :class="{ 'bg-orange': client.numero_totale_voti < 3 || client.numero_totale_voti_forno < 1, 'bg-green': client.numero_totale_voti >= 3 && client.numero_totale_voti_forno == 1 }">
                                <!-- <td style="width: 50px;" class="text-center">
                                    <router-link :to="{ name: 'DettaglioCliente', params: { id: client.client_id } }">
                                        <i class="fas fa-search fa-1x mr-4"></i>
                                    </router-link>
                                    {{ client.client_id }}
                                </td> -->
                                <td style="width: 30px;" class="text-right">{{ index + 1 }}</td>
                                <td style="width: 30px;" class="text-right">{{ client.nome_concorso }}</td>
                                <td style="width: 60px;" class="text-left">{{ shortCodeConcorso ? shortCodeConcorso : '' }}</td>
                                <td style="width: 280px;" class="text-left">{{ client.cognome }} {{ client.nome }}</td>
                                <td style="width: 60px;" class="text-left">{{ client.eta }}</td>
                                <td style="width: 150px;" class="text-left">{{ client.nazionalita }}</td>
                                <td style="width: 150px;" class="text-right">{{ client.numero_totale_voti }} / 3</td>
                                <td style="width: 150px;" class="text-right">{{ client.numero_totale_voti_forno }} / 1</td>
                                <td style="width: 150px;" class="text-right">{{ client.voto_totale }}</td>
                                <!-- <td style="width: 150px;" class="text-right">{{ client.aspetto_totale }}</td>
                                <td style="width: 150px;" class="text-right">{{ client.cottura_totale }}</td>
                                <td style="width: 150px;" class="text-right">{{ client.gusto_totale }}</td> -->
                                <td style="width: 150px;" class="text-right">{{ client.forno_totale }}</td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <button @click="exportTableToExcel" class="mt-5 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Esporta in Excel</button>
        </div>
        <div class="w-full pl-4" v-else>
            Caricamento in corso...
        </div>
    </div>
</template>



<script setup>
import { onMounted, ref, computed } from 'vue';
import axios from 'axios';
import RouterLinkButton from '../../components/global/RouterLinkButton.vue'
import * as XLSX from "xlsx";

let lista_concorsi = ref([])
let nazioni_list   = ref([])
const searchQueryConcorso    = ref('')
const searchQueryNazionalita = ref('')

const classifica = ref(null);
const shortCodeConcorso = ref(null);

function exportTableToExcel() {
    // Select the table
    const table = document.querySelector('table');
    
    // Convert the table to a worksheet
    const worksheet = XLSX.utils.table_to_sheet(table);
    
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, 'classifica-giudici-forno.xlsx');
}

function removeDuplicates(arr) {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}

const filteredConcorsi = computed(() => {
    
    let filteredResults = classifica.value || [];

    
    if (searchQueryConcorso.value && searchQueryNazionalita.value) {
        console.log('combo computed');
        filteredResults = filteredResults.filter(concorso => {
            return (
                concorso.nome_concorso?.toLowerCase().includes(searchQueryConcorso.value.toLowerCase()) &&
                concorso.nazionalita?.toLowerCase().includes(searchQueryNazionalita.value.toLowerCase())
                        );
        });

        if (filteredResults.length === 0) {
            return [];
        }
    }

    if (filteredResults.length === 0) {
        return [];
    }

    if (searchQueryConcorso.value) {
        filteredResults = filteredResults.filter(concorso => {
            return concorso.nome_concorso?.toLowerCase().includes(searchQueryConcorso.value.toLowerCase());
        });
    }

    if (filteredResults.length === 0) {
        return [];
    }

    if (searchQueryNazionalita.value) {
        filteredResults = filteredResults.filter(concorso => {
            return concorso.nazionalita?.toLowerCase().includes(searchQueryNazionalita.value.toLowerCase());
        });
    }

    if (filteredResults.length === 0) {
        return [];
    }

    filteredResults.sort((a, b) => b.index - a.index);
    filteredResults.sort((a, b) => b.forno_totale - a.forno_totale);

    console.log(filteredResults);

    return filteredResults;
});
  


const fetchClassifica = async () => {
    try {
        const response = await axios.get(`/api/classifica-giudici-forno`);
        
        nazioni_list = removeDuplicates(response.data.data.map(item => item.nazionalita));

        lista_concorsi = removeDuplicates(response.data.data.map(item => item.nome_concorso));
        classifica.value = response.data.data;
    } catch (error) {
        console.error(error);
    }
}

onMounted(() => {
    fetchClassifica();
});
</script>


<style>
.scroll-container {
    overflow-x: auto;
    width: 100%;
}

.table-wrapper {
    width: max-content;
    margin: 0 auto;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    padding: 8px;
    text-align: left;
}

.bg-even {
    background-color: #f2f2f2;
}

.bg-green {
    background-color: rgb(156, 255, 156);
}
.bg-orange {
    background-color: rgb(255, 200, 118);
}

.bg-green-500 {
    background-color: green;
}

.bg-red-500 {
    background-color: red;
}

.rounded-full {
    border-radius: 50%;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination {
    display: flex;
}

.pagination button {
    border: 1px solid #ccc;
    padding: 8px 12px;
    margin: 0 4px;
    border-radius: 4px;
}

.pagination button:first-child {
    margin-left: 0;
}

.pagination button:last-child {
    margin-right: 0;
}
</style>