<template>
    <div class="container max-w-4xl mx-auto flex mt-10">
        <div class="w-full pl-4 mb-6" v-if="team">
            <div class="flex">
                <div class="w-2/3">
                    <h1 class="text-2xl md:text-4xl text-left text-gray-900">
                        {{ team.nome_concorso }}
                    </h1>
                    <div class="bg-green-500 w-full h-1 my-4"></div>
                    <div class="text-md text-gray-700">
                        Concorrente Team 1: <i><b>{{ team.cliente_team1.cognome }} {{ team.cliente_team1.nome }} [{{ team.cliente_team1.shortCode }}]</b> per {{ team.id_concorso_team_1 }}</i>
                    </div>
                    <div class="text-md text-gray-700">
                        Concorrente Team 2: <i><b>{{ team.cliente_team2.cognome }} {{ team.cliente_team2.nome }} [{{ team.cliente_team2.shortCode }}]</b> per {{ team.id_concorso_team_2 }}</i>
                    </div>
                    <div class="text-md text-gray-700">
                        Concorrente Team 3: <i><b>{{ team.cliente_team3.cognome }} {{ team.cliente_team3.nome }} [{{ team.cliente_team3.shortCode }}]</b> per {{ team.id_concorso_team_3 }}</i>
                    </div>
                    <div class="text-md text-gray-700">
                        Concorrente Team 4: <i><b>{{ team.cliente_team4.cognome }} {{ team.cliente_team4.nome }} [{{ team.cliente_team4.shortCode }}]</b> per {{ team.id_concorso_team_4 }}</i>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full pl-4" v-else>
            Caricamento in corso...
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

const route = useRoute();
const id = ref(route.params.id);
const team = ref(null);


const fetchTeam = async () => {
    try {
        const response = await axios.get(`/api/teams/${id.value}`);
        team.value = response.data;

        const response_1 = await axios.get(`/api/get-concorso/${team.value.id_concorso_team_1}`);
        team.value.id_concorso_team_1 = response_1.data[0].nome_concorso;
        
        const response_2 = await axios.get(`/api/get-concorso/${team.value.id_concorso_team_2}`);
        team.value.id_concorso_team_2 = response_2.data[0].nome_concorso;

        const response_3 = await axios.get(`/api/get-concorso/${team.value.id_concorso_team_3}`);
        team.value.id_concorso_team_3 = response_3.data[0].nome_concorso;

        const response_4 = await axios.get(`/api/get-concorso/${team.value.id_concorso_team_4}`);
        team.value.id_concorso_team_4 = response_4.data[0].nome_concorso;

    } catch (error) {
        console.error(error);
    }
};

/* const getNomeConcorso = async (nome_concorso) => {
    const response2 = await axios.get(`/api/get-concorso/${nome_concorso}`);
    console.log(response2.data[0].nome_concorso);
    return response2.data[0].nome_concorso;
} */

onMounted(() => {
    fetchTeam();
});
</script>

<style>
.button {
    display: inline-block;
    padding: 10px 20px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.primary-button {
    background: white;
    color: gray;
    border-color: rgb(34, 197, 94);
    font-weight: bold;
}

.primary-button:hover {
    background: rgb(34, 197, 94);
    color: white;
}

.secondary-button {
    background: white;
    color: black;
    border: 1px solid orange;
}

.buttons {
    width: 300px;
    display: flex;
    justify-content: center;
}
</style>

