<template>
    <TopNavigation />
    <div id="" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">
        <div class="text-gray-900 text-xl">Nuova Data</div>
        <div class="bg-[#f8b526] mt-2 w-full h-1"></div>

        <div class="flex flex-wrap mt-8 mb-6">
            <div class="w-full md:w1/2 px-3 mb-3">
                <TextInput label="Data" placeholder="" v-model:input="data_concorso" inputType="date"
                    :error="errors.data_concorso ? errors.data_concorso[0] : ''" />
            </div>

        </div>

        <div class="flex flex-wrap mt-8 mb-6">
            <div class="w-full px-3">
                <SubmitFormButton btnText="Conferma" @submit="createDate" />
            </div>
        </div>
    </div>
    <FooterSection />
</template>

<script setup>
import TopNavigation from "../../components/structure/TopNavigation.vue";
import FooterSection from '../../components/structure/FooterSection.vue';
import { ref, onMounted } from 'vue'
import TextInput from '../../components/global/TextInput.vue'
import SubmitFormButton from '../../components/global/SubmitFormButton.vue'
import axios from 'axios';
import { useRouter } from 'vue-router';
import $wal from '../../sweetalert2';

const router = useRouter()

let errors = ref([])
let data_concorso = ref(null)

const createDate = async () => {
    errors.value = []

    let data = new FormData();

    data.append('data_concorso', data_concorso.value || '')

    try {
        await axios.post('api/date-concorsi', data)
        $wal.fire(
            'Nuova data creata!',
            '',
            'success'
        )

        router.push('/date-concorsi/show')

    } catch (err) {
        errors.value = err.response.data.errors;
    }
}

onMounted(() => {
    
});
/* '
import SubmitFormButton from '../../components/global/SubmitFormButton.vue'
import axios from 'axios';
import { useRouter } from 'vue-router';
import $wal from '../../sweetalert2';

import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

const router = useRouter()

let nome = ref(null)
let cognome = ref(null)
let short_code = ref(null)
//let nazionalita = ref(null)
let telefono = ref(null)
let taglia = ref(null)
let email = ref(null)
let luogo = ref(null)
let note = ref(null)
let errors = ref([])

const optionsNazione = ref([]);
const selNazione = ref(null)

const createDate = async () => {
    errors.value = []

    let data = new FormData();

    data.append('nome', nome.value || '')
    data.append('cognome', cognome.value || '')
    data.append('email', email.value || '')
    data.append('nazionalita', selNazione.value || '')
    data.append('telefono', telefono.value || '')
    data.append('taglia', taglia.value || '')
    data.append('luogo', luogo.value || '')
    data.append('shortCode', short_code.value || '')
    data.append('note', note.value || '')

    try {
        await axios.post('api/crea-cliente', data)
        $wal.fire(
            'Nuovo cliente creato!',
            '',
            'success'
        )

        router.push('/clienti/show')

    } catch (err) {
        errors.value = err.response.data.errors;
    }
}

function populateOptionsFromData(data) {
    optionsNazione.value = data.map((item) => ({
        value: item,
        label: item
    }));
}


 */

</script>