<template>
    <TopNavigation />
    <div id="" class="container max-w-6xl mx-auto pt-5 pb-20 px-6">
        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl mt-5">
                <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-4xl dark:text-white">Concorsi</h1>
            </div>        

        </div>


        <div class="flex gap-6 items-center">
            <div class="search">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Cerca per concorrente</label>
                <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input type="search" v-model="searchQuery" placeholder="Cerca nome e cognome concorrente..." id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                </div>
            </div>
    
            <div class="search">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Cerca per concorso</label>
                <!-- <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input type="search" v-model="searchQueryConcorso" placeholder="Cerca concorso..."  class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  />
                </div> -->

                <select v-model="searchQueryConcorso"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 ps-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="" selected>Tutte</option>
                    <option v-for="concorsi_list in lista_concorsi" :value="concorsi_list" :key="concorsi_list">{{concorsi_list}}</option>
                </select>
            </div>

            <div class="search">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Cerca per data</label>
    
    
                <select v-model="searchQueryDate"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 ps-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="" selected>Tutte le date</option>
                    <option v-for="lista_data in lista_date" :value="lista_data" :key="lista_data" >{{ formatDate(lista_data) }}</option>
                </select>
            </div>

        </div>
        


        <div class="relative overflow-x-auto mt-10">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Concorrente
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Nome Concorso (ShortCode)
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Data
                        </th>     
                        <th scope="col" class="px-6 py-3">
                            Email
                        </th> 
                        <th scope="col" class="px-6 py-3">
                            Concorrente aggiunto il
                        </th>  
                        <th scope="col" class="px-6 py-3">
                            ID ordine
                        </th>                        
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="concorso in filteredConcorsi" :key="concorso.id" :class="concorso.status == 'completed' ? ' bg-green' : 'bg-white'"  class="border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {{ concorso.nome + ' ' +concorso.cognome }}
                        </th>
                        <td class="px-6 py-4">
                            {{ concorso.nome_concorso }} <small><b>({{ concorso.shortCode }})</b></small>
                        </td>
                        <td class="px-6 py-4">
                            {{ formatDate(concorso.data_concorso) }} <br> {{ concorso.orario }}
                        </td> 
                        <td class="px-6 py-4">
                            {{ concorso.email }}
                        </td>   
                        <td class="px-6 py-4">
                            {{ formatDateWH(concorso.cuat) }}
                        </td>  
                        <td class="px-6 py-4">
                            {{ concorso.order_id !== "0" ? concorso.order_id : 'n.d.' }}
                        </td>                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <FooterSection />
</template>



<script setup>
import axios from 'axios'
import { onMounted, ref, computed } from 'vue'
import TopNavigation from "../../components/structure/TopNavigation.vue";
import FooterSection from '../../components/structure/FooterSection.vue';
import moment from 'moment'

let concorsi = ref([])
let lista_concorsi = ref([])
let lista_date = ref([])

const searchQuery = ref('')
const searchQueryConcorso = ref('')
const searchQueryDate = ref('')
//let computed_to_return = ref('')


const filteredConcorsi = computed(() => {
    
    
  if ( (!searchQuery.value || searchQuery.value == '') 
        && ( searchQueryConcorso.value == '' || !searchQueryConcorso.value)
        && ( searchQueryDate.value == '' || !searchQueryDate.value)
     ) {
    return concorsi.value
  }

  

  if( searchQueryConcorso.value.length  > 3){
    return concorsi.value.filter(concorso => {
        if( concorso.nome_concorso ){
            return concorso.nome_concorso.toLowerCase().includes(searchQueryConcorso.value.toLowerCase()) ||
                    concorso.shortCode.toLowerCase().includes(searchQueryConcorso.value.toLowerCase()) 
        }
        
    })
  }

  if( searchQuery.value.length > 3 ){
    return concorsi.value.filter(concorso => {
        if(concorso.nome && concorso.cognome){
            return concorso.nome.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            concorso.cognome.toLowerCase().includes(searchQuery.value.toLowerCase())
        }
        
    })
  }

  if( searchQueryDate.value ){
    return concorsi.value.filter(concorso => {
        if(concorso.data_concorso){
            return concorso.data_concorso.toLowerCase().includes(searchQueryDate.value.toLowerCase())
         }
        
    })
  }

  return concorsi.value
  
  
})


function formatDate(value) {
      return moment(value).format('DD/MM/YYYY');
}

function formatDateWH(value) {     
      return moment(value).format('DD/MM/YYYY HH:mm:ss');
}

function removeDuplicates(arr) {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}

async function fetchConcorsi() {
    try {
        const response = await axios.get('/api/get-lista-concorsi');
        concorsi.value = response.data

        lista_concorsi = removeDuplicates(response.data.map(item => item.nome_concorso));
        lista_date =  removeDuplicates(response.data.map(item => item.data_concorso));
        //lista_concorsi.value = response.data.nome_concorso
    } catch (error) {
        console.log(error);
    }
}

onMounted(() => {
    fetchConcorsi()
})
</script>