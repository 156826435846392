<template>
    <div id="" class="container mx-auto pt-5 pb-20 px-6">
        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl">
                Classifica {{ nome_concorso }}
            </div>
        </div>
        <div class="bg-green-500 w-full h-1 my-4"></div>
        <div v-if="classifica">
            <div class="scroll-container">
                <div class="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th style="width: 50px;" class="text-center"></th>
                                <th style="width: 30px;" class="text-right">Pos.</th>
                                <th style="width: 60px;" class="text-left">QrCode</th>
                                <th style="width: 280px;" class="text-left">Nome e Cognome</th>
                                <th style="width: 60px;" class="text-left">Età</th>
                                <th style="width: 150px;" class="text-left">Nazionalità</th>
                                <th style="width: 150px;" class="text-right">Stato</th>
                                <th style="width: 150px;" class="text-right">Stato forno</th>
                                <th style="width: 150px;" class="text-right">Voto Totale</th>
                                <th style="width: 150px;" class="text-right">Voto Aspetto</th>
                                <th style="width: 150px;" class="text-right">Voto Cottura</th>
                                <th style="width: 150px;" class="text-right">Voto Gusto</th>
                                <th style="width: 150px;" class="text-right">Voto Forno</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(client, index) in classifica" :key="client.shortCode"
                                :class="{ 'bg-orange': client.numero_totale_voti < 3, 'bg-green': client.numero_totale_voti >= 3 }">
                                <td style="width: 50px;" class="text-center">
                                    <router-link :to="{ name: 'DettaglioCliente', params: { id: client.id } }">
                                        <i class="fas fa-search fa-1x mr-4"></i>
                                    </router-link>
                                </td>
                                <td style="width: 30px;" class="text-right">{{ index + 1 }}</td>
                                <td style="width: 60px;" class="text-left">{{ client.shortCode }}</td>
                                <td style="width: 280px;" class="text-left">{{ client.cognome }} {{ client.nome }}</td>
                                <td style="width: 60px;" class="text-left">{{ client.eta }}</td>
                                <td style="width: 150px;" class="text-left">{{ client.nazionalita }}</td>
                                <td style="width: 150px;" class="text-right">{{ client.numero_totale_voti }} / 3</td>
                                <td style="width: 150px;" class="text-right">{{ client.numero_totale_voti_forno }} / 1</td>
                                <td style="width: 150px;" class="text-right">{{ client.voto_totale }}</td>
                                <td style="width: 150px;" class="text-right">{{ client.aspetto_totale }}</td>
                                <td style="width: 150px;" class="text-right">{{ client.cottura_totale }}</td>
                                <td style="width: 150px;" class="text-right">{{ client.gusto_totale }}</td>
                                <td style="width: 150px;" class="text-right">{{ client.forno_totale }}</td>
                                
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="w-full pl-4" v-else>
            <div class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                <span class="font-medium mr-3"><i class="fa-solid fa-spinner fa-spin"></i></span> Caricamento in corso
              </div>
        </div>
    </div>
</template>



<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';


const route = useRoute();
const id = ref(route.params.id);
const classifica = ref(null);
const nome_concorso = ref('');


const fetchClassifica = async () => {
    try {
        const response = await axios.get(`/api/classifica/${id.value}`);
        classifica.value = response.data.data;
        nome_concorso.value = classifica.value[0].nome_concorso;
    } catch (error) {
        console.error(error);
    }
}

onMounted(() => {
    fetchClassifica();
});
</script>


<style>
.scroll-container {
    overflow-x: auto;
    width: 100%;
}

.table-wrapper {
    width: max-content;
    margin: 0 auto;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    padding: 8px;
    text-align: left;
}

.bg-even {
    background-color: #f2f2f2;
}

.bg-green {
    background-color: rgb(156, 255, 156);
}
.bg-orange {
    background-color: rgb(255, 200, 118);
}

.bg-green-500 {
    background-color: green;
}

.bg-red-500 {
    background-color: red;
}

.rounded-full {
    border-radius: 50%;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination {
    display: flex;
}

.pagination button {
    border: 1px solid #ccc;
    padding: 8px 12px;
    margin: 0 4px;
    border-radius: 4px;
}

.pagination button:first-child {
    margin-left: 0;
}

.pagination button:last-child {
    margin-right: 0;
}
</style>