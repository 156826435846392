<template>
    <div id="EditOrder" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

        <div class="text-gray-900 text-xl">Aggiungi dettaglio ordine</div>
        <div class="bg-green-500 w-full h-1"></div>

        <div class="flex flex-wrap mt-4 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-3 hidden">
                <TextInput label="Order ID" placeholder="123456" v-model:input="order_id" inputType="text"
                    :error="errors?.order_id ? errors.order_id[0] : ''" />
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <label class="block 
                                uppercase 
                                tracking-wide 
                                text-xs         
                                font-bold 
                                mb-2        
                                text-gray-100
                                text-gray-900
                            ">
                    Status
                </label>
                <select
                    class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    v-model="status">
                    <option value="cancelled">cancelled</option>
                    <option value="checkout-draft">checkout-draft</option>
                    <option value="completed">completed</option>
                    <option value="on-hold">on-hold</option>
                    <option value="processed">processed</option>
                </select>
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <label class="block 
                                uppercase 
                                tracking-wide 
                                text-xs         
                                font-bold 
                                mb-2        
                                text-gray-100
                                text-gray-900
                            ">
                    Data
                </label>
                <select v-model="data_concorso" class="flex-1 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed align-middle">
                    <option v-for="data_lista in lista_date" :key="data_lista.data_concorso" v-bind:value="data_lista.data_concorso">{{data_lista.data_concorso}}</option>
                </select>
                <!-- <select
                    class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    v-model="data_concorso">
                    <option value="lunedi-13-novembre">lunedi-13-novembre</option>
                    <option value="martedi-14-novembre">martedi-14-novembre</option>
                    <option value="mercoledi-15-novembre">mercoledi-15-novembre</option>
                </select> -->
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <label class="block 
                                uppercase 
                                tracking-wide 
                                text-xs         
                                font-bold 
                                mb-2        
                                text-gray-100
                                text-gray-900
                            ">
                    Orario
                </label>
                <select
                    class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    v-model="orario">
                    <option value="10:00-11:00">10:00-11:00</option>
                    <option value="11:00-12:00">11:00-12:00</option>
                    <option value="12:00-13:00">12:00-13:00</option>
                    <option value="13:00-14:00">13:00-14:00</option>
                    <option value="14:00-15:00">14:00-15:00</option>
                    <option value="15:00-16:00">15:00-16:00</option>
                    <option value="16:00-17:00">16:00-17:00</option>
                    <option value="17:00-18:00">17:00-18:00</option>
                </select>
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <label class="block 
                                uppercase 
                                tracking-wide 
                                text-xs         
                                font-bold 
                                mb-2        
                                text-gray-100
                                text-gray-900
                            ">
                    Forno
                </label>
                <select
                    class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    v-model="forno">
                    <option value="legna">legna</option>
                    <option value="elettrico">elettrico</option>
                </select>
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <label class="block 
                                uppercase 
                                tracking-wide 
                                text-xs         
                                font-bold 
                                mb-2        
                                text-gray-100
                                text-gray-900
                            ">
                    Assistente
                </label>
                <select
                    class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    v-model="assistente" @change="handleChange">
                    <option value="1">Si</option>
                    <option value="0">No</option>
                </select>
            </div>
            <div v-if="trigger" class="flex flex-wrap">
                <div class="w-full md:w-1/2 px-3 mb-3">
                    <TextInput label="Nome Assistente" placeholder="Mario" v-model:input="nome_assistente" inputType="text"
                        :error="errors?.nome_assistente ? errors.nome_assistente[0] : ''" />
                </div>
                <div class="w-full md:w-1/2 px-3 mb-3">
                    <TextInput label="Cognome Assistente" placeholder="Rossi" v-model:input="cognome_assistente"
                        inputType="text" :error="errors?.cognome_assistente ? errors.cognome_assistente[0] : ''" />
                </div>
                <div class="w-full md:w-1/2 px-3 mb-3">
                    <TextInput label="Email Assistente" placeholder="mariorossi@email.com" v-model:input="email_assistente"
                        inputType="text" :error="errors?.email_assistente ? errors.email_assistente[0] : ''" />
                </div>
                <div class="w-full md:w-1/2 px-3 mb-3">
                    <TextInput label="Telefono Assistente" placeholder="00393333333333" v-model:input="telefono_assistente"
                        inputType="text" :error="errors?.telefono_assistente ? errors.telefono_assistente[0] : ''" />
                </div>
                <div class="w-full md:w-1/2 px-3 mb-3">
                    <TextInput label="Nazionalità Assistente" placeholder="Italiana" v-model:input="nazionalita_assistente"
                        inputType="text" :error="errors?.nazionalita_assistente ? errors.nazionalita_assistente[0] : ''" />
                </div>
                <div class="w-full md:w-1/2 px-3 mb-3">
                    <TextInput label="Città Assistente" placeholder="Napoli" v-model:input="citta_assistente"
                        inputType="text" :error="errors?.citta_assistente ? errors.citta_assistente[0] : ''" />
                </div>
                <div class="w-full md:w1/2 px-3">
                    <label class="block 
                                uppercase 
                                tracking-wide 
                                text-xs         
                                font-bold 
                                mb-2        
                                text-gray-100
                                text-gray-900
                            ">
                        Taglia Assistente
                    </label>
                    <select
                        class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                        v-model="taglia_assistente">
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                        <option value="XXL">XXL</option>
                        <option value="2XL">2XL</option>
                        <option value="3XL">3XL</option>
                        <option value="4XL">4XL</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap mt-8 mb-6">
            <button v-if="userStore.role === 'A'" class="button primary-button mt-2 mr-2 inline-block bg-red-500 hover:bg-red-700 
                           font-bold text-white no-underline px-4 py-3" @click="deleteDetails">
                Elimina
            </button>
            
            <SubmitFormButton btnText="Aggiorna Profilo" @click="updateDetails" />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import axios from 'axios'
import TextInput from '../../components/global/TextInput.vue';
import SubmitFormButton from '../../components/global/SubmitFormButton.vue';
import $wal from '../../sweetalert2';
import { useUserStore } from '../../store/user-store';

const userStore = useUserStore();

const router = useRouter()

const route = useRoute();
const id = ref(route.params.id);
const cliente_id = ref(route.params.cliente_id);

let order_id = ref(null)
let status = ref('completed')
let data_concorso = ref(null)
let orario = ref(null)
let assistente = ref(0)
let trigger = ref(false)
let forno = ref('legna')
let nome_assistente = ref(null)
let cognome_assistente = ref(null)
let email_assistente = ref(null)
let telefono_assistente = ref(null)
let nazionalita_assistente = ref(null)
let citta_assistente = ref(null)
let taglia_assistente = ref(null)
let errors = ref([])
let lista_date = ref([]);

const fetchDate = async () => {
    try {
        const response = await axios.get(`/api/date-concorsi`);
        lista_date.value = response.data;
    } catch (error) {
        console.error(error);
    }
};

watch(() => assistente.value, () => {
    trigger.value = !trigger.value
})

onMounted(() => {
    fetchConcorso();
    fetchDate();
    //eOggi();
})

/* var todaysDate = new Date();
var anno = todaysDate.getFullYear();
var mese = ( '0' + ( todaysDate.getMonth() +1 )  ).slice(-2);
var giorno = ('0' + todaysDate.getDate()).slice(-2);

var today_corrected = anno + '-' + mese + '-' +giorno;

function eOggi(  ){

    data_lista_selected = today_corrected;

} */

const updateDetails = async () => {
    errors.value = []

    let data = new FormData();

    data.append('id', id.value);
    data.append('assistente', assistente.value);
    data.append('order_id', order_id.value);
    data.append('status', status.value);
    data.append('data', data_concorso.value);
    data.append('orario', orario.value);
    data.append('forno', forno.value);
    data.append('nome_assistente', nome_assistente.value);
    data.append('cognome_assistente', cognome_assistente.value);
    data.append('email_assistente', email_assistente.value);
    data.append('telefono_assistente', telefono_assistente.value);
    data.append('nazionalita_assistente', nazionalita_assistente.value);
    data.append('citta_assistente', citta_assistente.value)
    data.append('taglia_assistente', taglia_assistente.value);

    try {
        await axios.post('api/concorsi/addOrderDetails?_method=PUT', data)

        $wal.fire(
            'Operazione riuscita!',
            'I dati sono stati correttamente salvati.',
            'success'
        )

        router.push(`/clienti/dettaglio-cliente/${cliente_id.value}`)
    } catch (err) {
        if (err.response) {
            errors.value = err.response.data.errors;
        } else {
            errors.value = err.message;
        }
        $wal.fire(
            'Errore!',
            'Si è verificato un problema in fase di salvataggio dei dati.',
            'error'
        )
    }
}

function handleChange() {
    console.log("Cambio: ", assistente.value, assistente.value == 1)
}

const fetchConcorso = async () => {
    try {
        const response = await axios.get(`/api/cliente-concorso/concorso/${id.value}`);
        if (response.data.data) {
            order_id.value = response.data.order_id;
            status.value = response.data.status;
            data_concorso.value = response.data.data;
            orario.value = response.data.orario;
            assistente.value = response.data.assistente;
            forno.value = response.data.forno;
            nome_assistente.value = response.data.nome_assistente;
            cognome_assistente.value = response.data.cognome_assistente;
            email_assistente.value = response.data.email_assistente;
            telefono_assistente.value = response.data.telefono_assistente;
            nazionalita_assistente.value = response.data.nazionalita_assistente;
            citta_assistente.value = response.data.citta_assistente;
            taglia_assistente.value = response.data.taglia_assistente;
        }
    } catch (error) {
        console.error(error);
    }
}

const deleteDetails = async () => {
    errors.value = []
    try {
        await axios.delete(`api/cliente-concorso/${id.value}`)

        $wal.fire(
            'Operazione riuscita!',
            'I dati sono stati eliminati.',
            'success'
        )

        router.push(`/clienti/dettaglio-cliente/${cliente_id.value}`)
    } catch (err) {
        if (err.response) {
            errors.value = err.response.data.errors;
        } else {
            errors.value = err.message;
        }
        $wal.fire(
            'Errore!',
            'Si è verificato un problema in fase di cancellazione dei dati.',
            'error'
        )
    }
}


</script>