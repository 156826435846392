<template>
    <TopNavigation />
    <div id="" class="container max-w-4xl mx-auto pt-5 pb-20 px-6">
        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl">
                Download archivi
            </div>
        </div>

        <div class="bg-green-500 w-full h-1 my-4"></div>
        <div class="flex py-2 font-bold items-center">
            <div class="w-3/5">
                Giudici
            </div>

            <div class="w-1/5 text-right">
                <button class="mr-4" @click="downloadGiudiciCSV">
                    <i class="fa-solid fa-cloud-arrow-down"></i>
                </button>
            </div>
        </div>
        <div class="flex py-2 font-bold items-center">
            <div class="w-3/5">
                Concorrenti
            </div>

            <div class="w-1/5 text-right">
                <button class="mr-4" @click="downloadConcorrentiCSV">
                    <i class="fa-solid fa-cloud-arrow-down"></i>
                </button>
            </div>
        </div>
        <div class="flex py-2 font-bold items-center">
            <div class="w-3/5">
                Assistenti
            </div>

            <div class="w-1/5 text-right">
                <button class="mr-4" @click="downloadAssistentiCSV">
                    <i class="fa-solid fa-cloud-arrow-down"></i>
                </button>
            </div>
        </div>
        <div class="flex py-2 font-bold items-center">
            <div class="w-3/5">
                Vincitori
            </div>

            <div class="w-1/5 text-right">
                <button class="mr-4" @click="downloadVincitoriCSV">
                    <i class="fa-solid fa-cloud-arrow-down"></i>
                </button>
            </div>
        </div>
        <div class="flex py-2 font-bold items-center">
            <div class="w-3/5">
                Classifiche
            </div>

            <div class="w-1/5 text-right">
                <button class="mr-4" @click="downloadClassificheCSV">
                    <i class="fa-solid fa-cloud-arrow-down"></i>
                </button>
            </div>
        </div>
        <div v-if="loading">
            Caricamento...
        </div>
    </div>
    <FooterSection />
</template>

<script setup>
import TopNavigation from '@/components/structure/TopNavigation.vue'
import FooterSection from '@/components/structure/FooterSection.vue';
import axios from 'axios';

/* GIUDICI */
const downloadGiudiciCSV = async () => {
    try {
        const response = await axios.get('api/giudici');
        const jsonData = response.data;

        const csvData = convertGiudiciJSONToCSV(jsonData);

        downloadGiudiciCSVFile(csvData);
    } catch (error) {
        console.error(error);
    }
};

const convertGiudiciJSONToCSV = (data) => {
    const fieldMapping = {
        id: "ID",
        first_name: "Nome",
        last_name: "Cognome",
        email: "Email",
        created_at: "Creato",
        updated_at: "Modificato",
        voti_count: "Voti",
    };

    const header = Object.values(fieldMapping).join(',') + '\n';

    const csv = data.map(row => {
        const rowData = Object.keys(fieldMapping).map(field => row[field]);
        return rowData.join(',');
    }).join('\n');

    return header + csv;
};

const downloadGiudiciCSVFile = (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'giudici.csv';
    a.click();
    window.URL.revokeObjectURL(url);
};

/* CONCORRENTI */

const downloadConcorrentiCSV = async () => {
    try {
        const response = await axios.get('api/clienti/export');
        const jsonData = response.data;

        const csvData = convertConcorrentiJSONToCSV(jsonData);

        downloadConcorrentiCSVFile(csvData);
    } catch (error) {
        console.error(error);
    }
};

const convertConcorrentiJSONToCSV = (data) => {
    const fieldMapping = {
        shortCode: "Short Code",
        nome: "Nome",
        cognome: "Cognome",
        email: "Email",
        created_at: "Creato",
        updated_at: "Modificato",
        accreditato: "Accreditato",
        nazionalita: "Nazione",
        telefono: "Telefono",
        taglia: "taglia",
        luogo_lavoro: "Luogo Lavoro",
    };

    const header = Object.values(fieldMapping).join(',') + '\n';

    const csv = data.map(row => {
        const rowData = Object.keys(fieldMapping).map(field => row[field]);
        return rowData.join(',');
    }).join('\n');

    return header + csv;
};

const downloadConcorrentiCSVFile = (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'concorrenti.csv';
    a.click();
    window.URL.revokeObjectURL(url);
};

/* ASSISTENTI */

const downloadAssistentiCSV = async () => {
    try {
        const response = await axios.get('api/assistenti');
        const jsonData = response.data;

        const csvData = convertAssistentiJSONToCSV(jsonData);

        downloadAssistentiCSVFile(csvData);
    } catch (error) {
        console.error(error);
    }
};

const convertAssistentiJSONToCSV = (data) => {
    const fieldMapping = {
        Nome_Assistente: "Nome_Assistente",
        Cognome_Assistente: "Cognome_Assistente",
        Email_Assistente: "Email_Assistente",
        Telefono_Assistente: "Telefono_Assistente",
        Nazionalita_Assistente: "Nazionalita_Assistente",
        Taglia_Assistente: "Taglia_Assitente",
        ShortCode_Cliente: "ShortCode_Assistente",
        Nome_Cliente: "Nome_Cliente",
        Cognome_Cliente: "Cognome_Cliente",
        ShortCode_Concorso: "ShortCode_Concorso",
        Nome_Concorso: "Nome_Concorso",
    };

    const header = Object.values(fieldMapping).join(',') + '\n';

    const csv = data.map(row => {
        const rowData = Object.keys(fieldMapping).map(field => row[field]);
        return rowData.join(',');
    }).join('\n');

    return header + csv;
};

const downloadAssistentiCSVFile = (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'assistenti.csv';
    a.click();
    window.URL.revokeObjectURL(url);
};

/* VINCITORI */
const downloadVincitoriCSV = async () => {
    try {
        const responseTeam = await axios.get('/api/teams/classifica');
        const jsonDataTeam = responseTeam.data;

        let csvData = convertVincitoriJSONToCSV(jsonDataTeam, true);

        const response = await axios.get('/api/classifiche-dashboard');
        const jsonData = response.data;

        csvData += convertVincitoriJSONToCSV(jsonData, false);

        downloadVincitoriCSVFile(csvData);
    } catch (error) {
        console.error(error);
    }
};

const convertVincitoriJSONToCSV = (data, blnHeader) => {
    const fieldMapping = {
        nome_concorso: "Concorso",
        nome: "Nome",
        cognome: "Cognome",
        nazionalita: "Nazionalità",
        voto: "Voto",
    };

    if (blnHeader) {
        const header = Object.values(fieldMapping).join(',') + '\n';

        // Mappa solo il primo record
        const firstRecord = data[0];
        const csv = Object.keys(fieldMapping).map(field => {
            if (field === 'nome_concorso') {
                // Sostituisci "Dream Team" con "Teams" nella colonna "Concorso"
                return 'Teams';
            } else if (field === 'nome') {
                return firstRecord['nome_concorso'];
            }
            else if (field === 'voto') {
                return firstRecord['voto_complessivo'];
            } else {
                return firstRecord[field];
            }
        }).join(',');

        return header + csv + '\n';
    }
    else {
        const csv = data.map(row => {
            const rowData = Object.keys(fieldMapping).map(field => row[field]);
            return rowData.join(',');
        }).join('\n');

        return csv;
    }

};

const downloadVincitoriCSVFile = (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'vincitori.csv';
    a.click();
    window.URL.revokeObjectURL(url);
};

/* CLASSIFICHE */
const downloadClassificheCSV = async () => {
    try {
        const response = await axios.get('api/concorsi');
        const jsonData = response.data;

        await Promise.all(jsonData.map(async (item) => {
            const id = item.id;
            const responseConcorso = await axios.get(`/api/classifica/${id}`);
            const jsonDataConcorso = responseConcorso.data.data;
            if (jsonDataConcorso && jsonDataConcorso.length > 0) {
                const csvData = convertClassificaJSONToCSV(jsonDataConcorso);
                await downloadClassificaCSVFile(csvData, item.nome_concorso);
            }
            await sleep(2000);
        }));
    } catch (error) {
        console.error(error);
    }
};

const convertClassificaJSONToCSV = (data) => {
    const fieldMapping = {
        shortCode: "ShortCode",
        cognome: "Cognome",
        nome: "Nome",
        eta: "Età",
        nazionalita: "Nazionalità",
        voto_totale: "Voto_Totale",
        aspetto_totale: "Voto_Aspetto",
        cottura_totale: "Voto_Cottura",
        gusto_totale: "Voto_Gusto",
        forno_totale: "Voto Forno",
        numero_totale_voti: "Voti",
    };

    const header = Object.values(fieldMapping).join(',') + '\n';

    const csv = data.map(row => {
        const rowData = Object.keys(fieldMapping).map(field => row[field]);
        return rowData.join(',');
    }).join('\n');

    return header + csv;
};

const downloadClassificaCSVFile = async (data, nome) => {
    try {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = nome + '.csv';
        console.log(a.download+data);
        a.click();
        window.URL.revokeObjectURL(url);
    } catch (errore) {
        console.log(errore);
    }
};

// Funzione per una pausa in millisecondi
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

</script>