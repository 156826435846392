<template>
  <div id="view-vota" class="container max-w-4xl mx-auto pt-5 pb-20 px-6">
    <div v-if="apiResponseStatus === 200">
      <div v-if="giudice">
        <div v-if="giudice.role === 'G'">
          <div class="flex justify-between items-center pt-1">
            <div id="titolo-voto" class="text-gray-900 text-xl">
              Ciao {{ giudice.email }} Vota per {{ concorso.nome_concorso }}
            </div>
          </div>
          <div class="bg-green-500 w-full h-1 my-4"></div>
          <div id="" class="mx-auto my-4 max-w-4xl">
            <div id="titolo-aspetto" class="text-gray-900 text-xl mb-2">
              Aspetto
            </div>
            <diV id="sottotitolo-aspetto" class="mt-2">
              Forma, Rotture, Crepe, Fori, Grandezza, Topping, Armonia cromatica e Ingredienti
            </diV>
            <div id="range-aspetto" class="my-2 font-bold italic text-xs">
              (Indicare i punti da 0 a 50)
            </div>
            <select
              class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              v-model="voto_aspetto">
              <option v-for="voto in voti" :key="voto" :value="voto">{{ voto }}</option>
              <!-- <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option> -->
            </select>
          </div>

          <div id="" class="mx-auto my-4 max-w-4xl">
            <div id="titolo-cottura" class="text-gray-900 text-xl mb-2">
              Cottura
            </div>
            <diV id="sottotitolo-cottura" class="mt-2">
              Disco e Cornicione: Bruciato, Dorato, Pallido
            </diV>
            <div id="range-cottura" class="my-2 font-bold italic text-xs">
              (Indicare i punti da 0 a 50)
            </div>
            <select
              class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              v-model="voto_cottura">
              <option v-for="voto in voti" :key="voto" :value="voto">{{ voto }}</option>
              <!-- <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option> -->
            </select>
          </div>

          <div id="" class="mx-auto my-4 max-w-4xl">
            <div id="titolo-gusto" class="text-gray-900 text-xl mb-2">
              Gusto
            </div>
            <div id="sottotitolo-gusto" class="mt-2">
              Acidità, Sapidità, Dolcezza, Grassezza, Scioglievolezza
            </div>
            <div id="range-gusto" class="my-2 font-bold italic text-xs">
              (Indicare i punti da 0 a 50)
            </div>
            <select
              class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              v-model="voto_gusto">
              <option v-for="voto in voti" :key="voto" :value="voto">{{ voto }}</option>
              <!-- <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option> -->
            </select>
          </div>

          <div id="" class="mx-auto my-4 max-w-4xl">
            <div class="text-gray-900 text-xl mb-2 font-bold">
              Voto Totale: {{ voto_totale }}
            </div>
          </div>

          <div class="float-right">
            <button class="button primary-button" @click="insertVote">Conferma Voto</button><!-- :disabled="votato" --> 
          </div>
        </div>
        <div v-else>
          <p class="text-center font-bold text-md text-gray-900">Utente non autorizzato!</p>
          <p class="text-center text-md text-gray-900">
            Vai alla pagina di
            <router-link to="../login" class="text-blue-500 no-underline hover:underline">
              Login
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="apiResponseStatus === 400">
      <p class="text-center font-bold text-md text-gray-900">{{ messaggio }}</p>
      <p class="text-center text-md text-gray-900">
        Vai alla pagina
        <router-link to="../dashgiudice" class="text-blue-500 no-underline hover:underline">
          iniziale
        </router-link>
      </p>
    </div>
    <div v-else>
      Attendere prego... {{ apiResponseStatus }}
    </div>

  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import axios from 'axios';
import $wal from '../../sweetalert2';
import { useUserStore } from '@/store/user-store';
import { computed } from 'vue'

const userStore = useUserStore();
const route = useRoute();
const router = useRouter()
const id = ref(route.params.id);
const giudice = ref(null);
const concorso = ref(null);
let errors = ref([])
let apiResponseStatus = ref(null)
let messaggio = ref(null)

let voti = [...Array(51).keys()];

let voto_aspetto = ref(1)
let voto_cottura = ref(1)
let voto_gusto = ref(1)
let votato = ref(false)

const voto_totale = computed(() => {
  return Number(voto_aspetto.value) + Number(voto_cottura.value) + Number(voto_gusto.value)
})

const fetchConcorso = async () => {
  try {
    const response = await axios.get(`/api/voti/${userStore.id}/${id.value}`);

    apiResponseStatus.value = response.status;
    giudice.value = response.data.giudice;
    concorso.value = response.data.concorso;

  } catch (error) {
    apiResponseStatus.value = error.response.status;
    messaggio.value = error.response.data.message;
    console.error('Questo è il catch', error);
  }
};

const insertVote = async () => {
  errors.value = []

  votato.value = true;

  let data = new FormData();

  data.append('user_id', userStore.id || '')
  data.append('concorso_cliente_id', id.value || '')
  data.append('voto', voto_totale.value)
  data.append('voto_aspetto', voto_aspetto.value)
  data.append('voto_cottura', voto_cottura.value)
  data.append('voto_gusto', voto_gusto.value)

  try {

    await axios.post('/api/insert-voto', data);

    $wal.fire(
      'Voto inserito!',
      '',
      'success'
    );
    router.push('/dashgiudice');
  } catch (error) {
    //errors.value = error.response.data.errors;
    console.log(error.response.data.message);
    $wal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.response.data.message
    });
    votato.value = false;
  }
}
onMounted(async () => {
  await fetchConcorso()
})
</script>

<style>
.button {
  display: inline-block;
  padding: 10px 20px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.primary-button {
  background: white;
  color: gray;
  border-color: #22C55E;
  font-weight: bold;
}

.primary-button:hover {
  background: #22C55E;
  color: white;
}
</style>

