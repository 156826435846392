<template>
    <div id="" class="mx-auto my-14 max-w-4xl ">
        <div class="text-gray-900 text-xl">I numeri dell'App.</div>
        <div class="bg-green-500 w-full h-1 mb-4 mt-2"></div>
        <div v-if="concorrentiCount || giudiciCount || concorsiCount" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">

            <RouterLink to="/clienti/show" class="p-8 bg-blue-100 rounded-xl flex items-center">
                <i class="fas fa-user fa-2x mr-4"></i>
                <p class="font-bold">{{ concorrentiCount }} Concorrenti</p>
            </RouterLink>

            <div class="p-8 bg-yellow-100 rounded-xl flex items-center">
                <i class="fas fa-gavel fa-2x mr-4"></i>
                <p class="font-bold">{{ giudiciCount }} Giudici</p>
            </div>

            <div class="p-8 bg-red-100 rounded-xl flex items-center">
                <i class="fas fa-trophy fa-2x mr-4"></i>
                <p class="font-bold">{{ concorsiCount }} Concorsi</p>
            </div>


                <div  v-if="role === 'U'" class="button-only-segretary">
                    <router-link to="/clienti/show" style=" width: 100%; text-align: center; " class="float-right bg-trasparent text-gray-500 font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded hover:bg-green-500 border-green-500">
                        <div class="text-2xl">
                            Lista concorrenti
                        </div>
                    </router-link>
                </div>
                
                <div v-if="role === 'U'" class="button-only-segretary">
                    <router-link to="/clienti/create-cliente" style=" width: 100%; text-align: center; " class="float-right bg-trasparent text-gray-500 font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded hover:bg-green-500 border-green-500">
                        <div class="text-2xl">
                            Aggiungi concorrente
                        </div>
                    </router-link>
                </div>

                <div v-if="role === 'U'" class="button-only-segretary">
                    <router-link to="/teams/show" style=" width: 100%; text-align: center; " class="float-right bg-trasparent text-gray-500 font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded hover:bg-green-500 border-green-500">
                        <div class="text-2xl">
                            Lista Teams
                        </div>
                    </router-link>
                </div>
            
        </div>
        <div v-else>
            <div class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                <span class="font-medium mr-3"><i class="fa-solid fa-spinner fa-spin"></i></span> Caricamento in corso
              </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useUserStore } from '../../../store/user-store';
import axios from 'axios';

const concorrentiCount = ref(null);
const giudiciCount = ref(null);
const concorsiCount = ref(null);
let role = ref('')
const userStore = useUserStore();

async function fetchCounters() {
    try {
        const response = await axios.get('/api/counters-dashboard')
        const data = response.data.data;

        concorsiCount.value = data.concorsi_count;
        concorrentiCount.value = data.concorrenti_count;
        giudiciCount.value = data.giudici_count;
    } catch (error) {
        console.error('Errore durante il recupero dei contatori:', error);
    }
}

onMounted(() => {
    fetchCounters();

    role.value = userStore && !isNaN(userStore.id) ? userStore.role : '';

    // Richiama fetchCounters ogni 15 minuti
    setInterval(() => {
        fetchCounters();
    }, 900000); // 900000 millisecondi = 15 minuti
});
</script>