<template>
    <div>
        <div id="" class="container max-w-4xl mx-auto pt-20 pb-20 px-6 mb-20">

        <form class="mb-10">  
            
            <div class="mb-10">
                <label for="abilita_votazione" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Abilita votazione</label>
                <select id="abilita_votazione" @change="confermaAbilitaVotazione()" v-model="abilita_votazione" required class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="1">Si</option>
                    <option value="0">No</option>                
                </select>
            </div>

            <div id="blocco-voto-forno">
                <label for="voto_forno" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white ">Trascrivi voto dal responsabile di forno</label>
                <div class="mb-5 flex gap-3 items-center">
                    <div>                    
                        <input id="input-voto-forno" type="number" v-model="voto_forno" max="30" min="0" class=" max-w-[100px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
                    </div>
                    <div>
                        <button id="btn-conferma-voto-forno" type="button" @click="confermaVotoForno()" class="bg-trasparent text-gray-500 font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded hover:bg-green-500 border-green-500 w-full text-gray-900 text-center text-lg mt-2">CARICA VOTO</button>
                    </div>
                </div>        
            </div>   
        
            
        </form>
  

            <div class="text-gray-900 text-xl">Comunica il codice al giudice</div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="text-gray-900 text-4xl w-full text-center mt-4">
                {{ shortCode }}
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="text-gray-900 text-2xl w-full text-center mt-4">
                Voti espressi: {{ numVoti }} / 3
                <br>Voto Forno: {{ votiFornoEspressi  }} / 1                
            </div>

            <div class="my-auto mt-4">
                <RouterLinkButton class="w-full 
                            text-gray-900 
                            text-center 
                            text-lg
                            mt-2" btnText="Chiudi" color="green" url="/dashassistenza" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, /* watch */ } from 'vue'
import { useRoute } from 'vue-router';
import axios from 'axios';
import $wal from '../../sweetalert2';
import { useUserStore } from '@/store/user-store';
import RouterLinkButton from '../../components/global/RouterLinkButton.vue';

const route = useRoute()

const codice = ref('');
const userStore = useUserStore();
const shortCode = ref('');
const numVoti = ref('');
const id = ref(route.params.id);
const voto_forno = ref(0);
const abilita_votazione = ref(0);

let   votiFornoEspressi = ref('');

/* watch(() => abilita_votazione.value, () => {
    confermaAbilitaVotazione();
}) */

async function confermaAbilitaVotazione(){
    try {
        const response = await axios.post(`api/conferma-abilita-votazione/${userStore.id}/${id.value}?_method=PUT`,{
            abilita_votazione: abilita_votazione.value
        });
        
        $wal.fire({
                icon: 'success',
                title: 'Ottimo',
                text: response.data
            });

            fetchVotoForno();

        document.getElementById('abilita_votazione').disabled = true;

    } catch (error) {
        console.error(error.response.data.message);
        $wal.fire({
            icon: 'error',
            title: 'Errore...',
            text: error.response.data.message
        });
    }
}

async function checkAbilitaVotazione(){
    try {
        const response = await axios.get(`/api/check-abilita-votazione/${userStore.id}/${id.value}`);

        abilita_votazione.value = response.data

        if( response.data == 1 ){
            document.getElementById('abilita_votazione').disabled = true;
        }

    } catch (error) {    
        console.error('Questo è il catch', error);
     }
}

const fetchVotoForno = async () => {
  try {
    const response = await axios.get(`/api/count-voti-forno/${userStore.id}/${id.value}`);

    console.log(response.data);
    votiFornoEspressi.value = response.data

    if( response.data == 1 ){
        document.getElementById('blocco-voto-forno').remove()
        /* document.getElementById('input-voto-forno').disabled = true;
        document.getElementById('btn-conferma-voto-forno').disabled = true; */
    }

  } catch (error) {    
    console.error('Questo è il catch', error);
  }
};

async function getShortCode() {
    try {
        codice.value = route.params.id
        const response = await axios.get(`api/cerca-voto-manuale/${codice.value}`);

        shortCode.value = response.data.shortCode;
        numVoti.value = response.data.numeroVoti;
    } catch (error) {
        console.log(error);
    }
}

async function confermaVotoForno(){
    try {
        const response = await axios.post(`api/vota-forno/${userStore.id}/${id.value}?_method=PUT`, {
            voto_forno: voto_forno.value
        });
        
        $wal.fire({
                icon: 'success',
                title: 'Ottimo',
                text: response.data
            });

            fetchVotoForno();

        document.getElementById('input-voto-forno').disabled = true;

    } catch (error) {
        console.error(error.response.data.message);
        $wal.fire({
            icon: 'error',
            title: 'Errore...',
            text: error.response.data.message
        });
    }
}

/* watch(() => voto_forno.value, async () => {
    try {
        const response = await axios.post(`api/vota-forno/${userStore.id}/${id.value}?_method=PUT`, {
            voto_forno: voto_forno.value
        });
        
        $wal.fire({
                icon: 'success',
                title: 'Ottimo',
                text: response.data
            });

        document.getElementById('input-voto-forno').disabled = true;

    } catch (error) {
        console.error(error.response.data.message);
        $wal.fire({
            icon: 'error',
            title: 'Errore...',
            text: error.response.data.message
        });
    }
}) */

onMounted(async () => {
    await checkAbilitaVotazione()
    await getShortCode()
    await fetchVotoForno()
})

</script>