<template>
    <div id="" class="container max-w-4xl mx-auto pt-5 pb-20 px-6">
        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl">
                Giudici
            </div>

            <RouterLinkButton btnText="Nuovo Giudice" color="green" url="/register" />
        </div>

        <div class="bg-green-500 w-full h-1 my-4"></div>
        <div v-if="giudici">
            <div class="flex py-2 font-bold items-center">

                <div class="w-1/6 ">
                    Dettaglio
                </div>
                <div class="w-1/6">
                    Id
                </div>
                <div class="w-3/6">
                    Cognome e Nome
                </div>
                <div class="w-3/6">
                    Tipologia
                </div>
                <div class="w-1/6 text-right">
                    Voti
                </div>

            </div>

            <div v-for="giudice in giudici" :key="giudice.id" id="elenco-giudici" class="flex py-2 items-center">

                <div class="w-1/6 text-center">
                    <router-link :to="{ name: 'ShowVotiGiudice', params: { id: giudice.id } }">
                        <i class="fas fa-search fa-1x mr-4"></i>
                    </router-link>
                </div>
                <div class="w-1/6">
                    {{ giudice.id }}
                </div>
                <div class="w-3/6">
                    {{ giudice.last_name }} {{ giudice.first_name }}
                </div>
                <div class="w-1/6 text-right">
                    {{ giudice.voti_count }}
                </div>
                <div class="w-1/6 text-right">
                    {{ giudice.tipologia_giudice }}
                </div>
            </div>
        </div>
        <div class="w-full pl-4" v-else>
            Caricamento in corso...
        </div>
    </div>
</template>

<style>
#elenco-giudici {
    background-color: #fff;
}

#elenco-giudici:nth-child(even) {
    background-color: #f2f2f2;
}
</style>

<script setup>
import axios from 'axios'
import { onMounted, ref } from 'vue'
import RouterLinkButton from '../../components/global/RouterLinkButton.vue'

const giudici = ref([])

async function fetchGiudici() {
    try {
        const response = await axios.get('/api/giudici');
        giudici.value = response.data;
    } catch (error) {
        console.log(error);
    }
}

onMounted(() => {
    fetchGiudici()
})
</script>