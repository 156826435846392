<template>
  <TopNavigation />
  <CounterSection />
  <div v-if="userStore.role === 'A'">
    <ClassificheSection />
  </div>
  <FooterSection />
</template>

<script setup>
import TopNavigation from "@/components/structure/TopNavigation.vue";
import FooterSection from '../components/structure/FooterSection.vue';
import CounterSection from '../components/partials/dashboard/CounterSection.vue';
import ClassificheSection from '../components/partials/dashboard/ClassificheSection.vue';

import { useUserStore } from '../store/user-store';

const userStore = useUserStore();
/* console.log(userStore.role);
 */
</script>


